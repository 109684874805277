import { Button, CssBaseline, Grid, Paper, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./CRRDeficiencyReport.module.css";
import * as msgConstants from "../../../../../common/MessageConstants";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import * as docx from "docx";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../../../components/UI/Alert/Alert";
import { saveAs } from "file-saver";
import GetAppIcon from "@mui/icons-material/GetApp";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface DeficiencyProps {
  from?: string;
  props?: any;
  reportBlobData?: any;
  handleChange?: any;
  location?: any;
  partnerLogo?: any;
  clientLogo?: any;
  partnerImageDimension?: any;
  clientImageDimension?: any;
}

export const CRRDeficiencyReport: React.FC<DeficiencyProps> = ({
  from,
  reportBlobData,
  props,
  handleChange,
  partnerLogo,
  clientLogo,
  partnerImageDimension,
  clientImageDimension,
}: DeficiencyProps) => {
  const history = useNavigate();
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const preparedForBy = "preparedFor/By";
  const location = useLocation();
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const componentRef = React.useRef<HTMLHeadingElement>(null);
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState<any>({});
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");
  const [partnerImage, setPartnerImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [deficienciesList, setDeficienciesList] = useState<any>(null);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;

  useEffect(() => {
    const controller = new AbortController();
    getManagementData();
    if (props) {
      setParam(props);
    } else {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    }
    return () => controller.abort();
  }, []);

  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  const getBannerImages = async () => {
    await fetch(`${process.env.PUBLIC_URL}/images/ExecutiveSummary.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    await fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData.logo && clientData.logo[0]) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
        await fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setCPAImage(base64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
      if (partnerData.logo && partnerData.logo[0]) {
        const partnerLogoUrl = partnerData.logo[0].url.slice(1);
        await fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  
  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  useEffect(() => {
    getBannerImages();
  }, [partnerData, clientData]);

  useEffect(() => {
    if (param.clientInfo) {
      GetOrganizationDataOfPartner({
        variables: {
          where: {
            contact_id: param?.clientInfo.partnerId,
          },
        },
      });
      GetOrganizationDataOfClient({
        variables: {
          where: {
            id: param?.clientInfo.clientOrgId,
            subtype: "Client",
          },
        },
      });
    }
  }, [param, props]);

  useEffect(() => {
    if (
      from &&
      from === "result" &&
      param.clientInfo &&
      deficienciesList !== null && cpaImage && partnerImage
    ) {
      setTimeout(() => {
        generate();
      }, 800);
    }
  }, [bannerImage, partnerData.contact_id, deficienciesList, cpaImage, partnerImage]);

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param?.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const doc = new docx.Document({
    numbering: {
      config: [
        {
          reference: "top_categories",
          levels: [
            {
              level: 0,
              format: docx.LevelFormat.DECIMAL,
              text: "%1",
              alignment: docx.AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 },
                },
              },
            },
          ],
        },
        {
          reference: "questions",
          levels: [
            {
              level: 0,
              format: docx.LevelFormat.DECIMAL,
              text: "%1",
              alignment: docx.AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 },
                },
              },
            },
          ],
        },
        {
          reference: "numbering-style-1",
          levels: [
            {
              level: 0,
              format: docx.LevelFormat.UPPER_ROMAN,
              text: "%1)", // results in '1)', '2)', etc.
              alignment: docx.AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 },
                },
              },
            },
            {
              level: 1,
              format: docx.LevelFormat.DECIMAL,
              text: "%2.", // results in '1.', '2.', etc.
              alignment: docx.AlignmentType.START,
              style: {
                paragraph: {
                  // Increase indentation for nested levels
                  indent: { left: 1440, hanging: 980 },
                },
              },
            },
            // And so on...
          ],
        },
      ],
    },
    styles: {
      default: {
        heading1: {
          run: {
            font: "Helvetica",
            size: 36,
            bold: true,
            color: "#4b0079",
          },
          paragraph: {
            alignment: docx.AlignmentType.LEFT,
            spacing: { line: 340 },
          },
        },
        heading2: {
          run: {
            font: "Helvetica",
            size: 30,
            bold: true,
            color: "#0b4785",
          },
          paragraph: {
            spacing: { line: 340 },
          },
        },
        heading3: {
          run: {
            font: "Helvetica",
            size: 20,
          },
          paragraph: {
            spacing: { line: 276 },
          },
        },
        heading4: {
          run: {
            font: "Helvetica",
            size: 20,
          },
          paragraph: {
            alignment: docx.AlignmentType.JUSTIFIED,
          },
        },
      },
      paragraphStyles: [
        {
          id: "normalPara",
          name: "Normal Para",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Helvetica",
            size: 20,
          },
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
            rightTabStop: docx.TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: "mainHeading",
          name: "Normal Para2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Helvetica",
            size: 80,
            color: "#4B0079",
            bold: true,
          },
        },
        {
          id: "date",
          name: "Aside",
          basedOn: "Normal",
          next: "Normal",
          run: {
            font: "Helvetica",
            color: "#4B0079",
            size: 40,
            bold: true,
          },
        },
        {
          id: "bulletText",
          name: "Normal para",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Helvetica",
            size: 20,
          },
        },
        {
          id: "customFontCng",
          quickFormat: true,
          run: {
            font: "Helvetica",
            size: 20,
          },
        },
        {
          id: "miniHeader",
          name: "Normal para",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,

          run: {
            font: "Helvetica",
            size: 24,
            italics: true,
            bold: true,
          },
        },
        {
          id: "TableHeading",
          name: "Normal Para2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,

          run: {
            font: "Helvetica",
            size: 20,
            color: "#4B0079",
            bold: true,
          },
        },
        {
          id: "BoldPara",
          name: "Normal Para3",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,

          run: {
            font: "Helvetica",
            size: 20,
            bold: true,
          },
        },
        {
          id: preparedForBy,
          name: "Aside",
          basedOn: "Normal",
          next: "Normal",
          run: {
            font: "Helvetica",
            color: "#4B0079",
            size: 20,
            bold: true,
          },
        },
        {
          id: "clientPartner",
          name: "Aside",
          basedOn: "Normal",
          next: "Normal",
          run: {
            font: "Helvetica",
            size: 20,
          },
        },
      ],
    },
  });

  const deficiencies: any[] = [];

  const keyToCategory: any = {
    AM: "Asset Management",
    CM: "Controls Management",
    CCM: "Configuration and Change Management",
    VM: "Vulnerability Management",
    IM: "Incident Management",
    SCM: "Service Continuity Management",
    RM: "Risk Management",
    EDM: "External Dependencies Management",
    TA: "Training and Awareness",
    SA: "Situational Awareness",
  };

  const getManagementData = async () => {
    try{
      setLoading(true)
      await fetch(`${CSET_URL}api/reportscrr/getCrrModel`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((crrModel: any) => {
          let categories: any = [];
          // Build up deficiencies list
          crrModel.reportData.deficienciesList.forEach((matAns: any) => {
            const domain = matAns.mat.question_Title.split(":")[0];
            const dElement = categories.find(
              (e: any) => e.cat === keyToCategory[domain]
            );
            if (!dElement) {
              categories.push({
                cat: keyToCategory[domain],
                matAnswers: [matAns],
              });
            } else {
              dElement.matAnswers.push(matAns);
            }
          });
  
          // We want the report to have the categories in a particular order, so manually add them in order
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["AM"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["CM"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["CCM"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["VM"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["IM"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["SCM"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["RM"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["EDM"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["TA"])
          );
          pushDeficiencyCategory(
            categories.find((e: any) => e.cat === keyToCategory["SA"])
          );
  
          // Sort the list
          deficiencies.forEach((e) => {
            e.matAnswers.sort((a: any, b: any) => {
              return (
                a.mat.question_Title
                  .split("-")[0]
                  .localeCompare(b.mat.question_Title.split("-")[0]) ||
                a.mat.question_Text.localeCompare(b.mat.question_Text)
              );
            });
          });
  
          // mark questions followed by a child for border display
          deficiencies.forEach((e) => {
            for (let i = 0; i < e.matAnswers.length; i++) {
              if (e.matAnswers[i + 1]?.mat.parent_Question_Id != null) {
                e.matAnswers[i].isFollowedByChild = true;
              }
            }
          });
          setDeficienciesList(deficiencies);
          setLoading(false)
        }).catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    }
    catch(err){
      sentry_error_catch(err,setLoading,setFormState)
    }
  };

  const pushDeficiencyCategory = (cat: any) => {
    if (cat) {
      deficiencies.push(cat);
    }
  };

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const handleBack = () => {
    history(routeConstant.CRR_REPORTS, { state: { param } });
  };

  const generateDocxTable = (deficienciesList: any) => {
    const arr: any = [];

    deficienciesList.forEach((deficiency: any, index: any) => {
      const table = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: deficiency.cat,
                        bold: true,
                        size: 24,
                      }),
                    ],
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "fcfaff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      deficiency.matAnswers.forEach((matAnswer: any) => {
        table.addChildElement(
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun(
                        `Practice ${matAnswer.mat.question_Title}`
                      ),
                    ],
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "fcfaff",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    children: [new docx.TextRun(matAnswer.mat.question_Text)],
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "fcfaff",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun(
                        matAnswer.answer.answer_Text === "Y"
                          ? "Yes"
                          : matAnswer.answer.answer_Text === "N"
                          ? "No"
                          : "Unanswered"
                      ),
                    ],
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "fcfaff",
                  color: "auto",
                },
                columnSpan: 1,
              }),
            ],
          })
        );
      });
      arr.push(table);
    });
    return arr;
  };

  const generate = async () => {
    setLoading(true);
    console.log("Deficiency Report");
    const exeSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
    const defaultcpaLogo = defaultCPAImage ? defaultCPAImage.split(",")[1] : "";
    const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
    const msplogo = partnerImage ? partnerImage.split(",")[1] : defaultcpaLogo;

    const image5 = await docx.Media.addImage(
      doc,
      Uint8Array.from(atob(exeSummaryLogo), (c) => c.charCodeAt(0)),
      600,
      350
    );

    const image6 = await docx.Media.addImage(
      doc,
      Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
      fitImg(50, cpaImageDimensions),
      50
    );

    const image7 = await docx.Media.addImage(
      doc,
      Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
      fitImg(50, partnerImageDimensions),
      50
    );

    const footerLogo = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
      fitImg(50, partnerImageDimensions),
      50,
      {
        floating: {
          horizontalPosition: {
            align: docx.HorizontalPositionAlign.RIGHT,
          },
          verticalPosition: {
            align: docx.VerticalPositionAlign.BOTTOM,
          },
        },
      }
    );
    const bannertable = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  heading: docx.HeadingLevel.HEADING_1,
                  style: preparedForBy,
                  children: [
                    new docx.TextRun({
                      text: "Prepared for",
                      bold: true,
                    }),
                  ],
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),

                new docx.Paragraph(image6),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph({
                  text: param?.clientInfo?.name,
                  style: "clientPartner",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.2),
                right: docx.convertInchesToTwip(1.8),
              },
              columnSpan: 5,
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },

                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  heading: docx.HeadingLevel.HEADING_1,
                  style: preparedForBy,
                  children: [
                    new docx.TextRun({
                      text: "Prepared By",
                      bold: true,
                    }),
                  ],
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph(image7),
                new docx.Paragraph({
                  text: "",
                }),
                new docx.Paragraph({
                  text: partnerData?.contact_id?.name,
                  style: "clientPartner",
                }),
                new docx.Paragraph({
                  text: "",
                  style: "clientPartner",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.5),
                right: docx.convertInchesToTwip(2),
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              columnSpan: 5,
              shading: {
                fill: "ffffff",
                color: "auto",
              },
            }),
          ],
        }),
      ],
    });

    doc.addSection({
      children: [
        new docx.Paragraph(image5),
        new docx.Paragraph({
          text: "CRR Deficiency Report",
          style: "mainHeading",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: moment().format("DD MMMM YYYY"),
          heading: docx.HeadingLevel.HEADING_1,
          style: "date",
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),

        bannertable,
      ],
    });

    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: msgConstants.DEFICIENCY_REPORT,
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: `Notification`,
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: `This deficiency report lists the statements that are not currently compliant or "No" answers. `,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 100,
          },
        }),
        new docx.Paragraph({
          text: `This is often referred to as the GAP Report. This report intends to list the gaps, assist users of the report in identifying gaps, prioritizing work, and beginning to make a plan to address the gaps by implementing the controls necessary to come into compliance with the associated statement. `,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 100,
          },
        }),
        new docx.Paragraph({
          text: `The percentage gap in each domain is also listed and will help to determine the priority. CRR is a cumulative maturity model meaning lower levels should be completed before moving to higher levels. Ideally baseline should be completed before focusing efforts on controls in evolving and so forth up the line of maturity levels. `,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
      ],
    });
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Deficiencies",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 300,
          },
        }),
        ...generateDocxTable(deficienciesList),
      ],
    });

    docx.Packer.toBlob(doc)
      .then(async (blob: any) => {
        if (from && from === "result") {
          await Promise.resolve()
            .then(() => {
              setLoading(false);
              reportBlobData = blob;
              reportBlobData["name"] = "Deficiency_Report.docx";
              handleChange(reportBlobData);
            })
            .catch((err) => {
              console.log(err);
              Sentry.captureException(err)
            });
        } else {
          await Promise.resolve(saveAs(blob, "Deficiency_Report.docx"))
            .then(() => {
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              Sentry.captureException(err)
            });
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
      <div>
        <CssBaseline />
        <Grid container ref={componentRef}>
          <Grid item md={12}>
            <Typography component="h5" variant="h1">
              {msgConstants.DEFICIENCY_REPORT}
            </Typography>
            <Grid item xs={12} sm={6} className={styles.FooterActions}>
              <div className={styles.prevBtn}>
                <Button
                  id="previous-button"
                  variant="contained"
                  color="primary"
                  onClick={handleBack}>
                  {"Previous"}
                </Button>
                <Button
                  id="doc-generate-button-2"
                  variant="contained"
                  color="primary"
                  onClick={() => generate()}>
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; Doc
                </Button>
              </div>
            </Grid>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formState.errMessage}
              </Alert>
            ) : null}
            <Paper className={styles.paper}>
              {loading ? <SimpleBackdrop /> : null}

              <div>
                <p>
                  This deficiency report lists the statements that are not
                  currently compliant or "No" answers.
                </p>
                <p>
                  This is often referred to as the GAP Report. This report
                  intends to list the gaps, assist users of the report in
                  identifying gaps, prioritizing work, and beginning to make a
                  plan to address the gaps by implementing the controls
                  necessary to come into compliance with the associated
                  statement.
                </p>
                <p>
                  The percentage gap in each domain is also listed and will help
                  to determine the priority. CRR is a cumulative maturity model
                  meaning lower levels should be completed before moving to
                  higher levels. Ideally baseline should be completed before
                  focusing efforts on controls in evolving and so forth up the
                  line of maturity levels.
                </p>
              </div>
              <Grid item xs={8} md={12} sm={8} className={styles.FooterActions}>
                <div className={styles.securityPlanTable}>
                  {deficienciesList &&
                    deficienciesList.map((deficiency: any, index: number) => {
                      return (
                        <table
                          id="secondTable"
                          key={index}
                          className={styles.tableStyle}>
                          <tr>
                            <td colSpan={3}>
                              <Typography
                                component="h3"
                                variant="h2"
                                style={{ fontWeight: 600 }}>
                                {deficiency.cat}
                              </Typography>{" "}
                            </td>
                          </tr>
                          {deficiency.matAnswers.map(
                            (matAnswer: any, indexs: number) => {
                              return (
                                  <tr key={indexs}>
                                    <td colSpan={1}>
                                      {"Practice "}
                                      {matAnswer.mat.question_Title}{" "}
                                    </td>
                                    <td colSpan={2}>
                                      <span
                                        dangerouslySetInnerHTML={createDangerousHTML(
                                          matAnswer.mat.question_Text
                                        )}></span>
                                    </td>
                                    <td colSpan={1}>
                                      {matAnswer.answer.answer_Text === "Y"
                                        ? "Yes"
                                        : matAnswer.answer.answer_Text === "N"
                                        ? "No"
                                        : "Unanswered"}{" "}
                                    </td>
                                  </tr>
                              );
                            }
                          )}
                        </table>
                      );
                    })}
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}>
                {"Previous"}
              </Button>
              <div className={styles.borderButton}>
                <Button
                  id="doc-generate-button-2"
                  variant="contained"
                  color="primary"
                  onClick={() => generate()}>
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; Doc
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
  );
};

export default CRRDeficiencyReport;
