import React, { useState, useEffect } from "react";
import styles from "./CMMC2Reports.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import stepper from "../../../../common/csetStepperMenu.json";
import standardStepper from "../../../../common/csetStandardStepperMenu.json";
import { setStepper } from "../../../../common/SetStepper";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import { saveAs } from "file-saver";
import * as msgConstants from "../../../../common/MessageConstants";
import { HISTORY } from "../../../../graphql/mutations/AuditTrail";
import { useMutation, useApolloClient } from "@apollo/client";
import Alert from "../../../../components/UI/Alert/Alert"
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

export const CMMC2Reports: React.FC = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const stepperObject = stepper;
  const standardStepperObject = standardStepper;
  const client = useApolloClient();
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  useEffect(() => {
    setParam(location?.state[Object.keys(location?.state)[0]]);
  }, []);

  const [auditTrail] = useMutation(HISTORY);

  useEffect(() => {
    setStepper(client, stepperObject.reports.name, stepperObject.reports.value);
    auditTrail({
      variables: {
        auditTrailInput: {
          individual: param.individualId,
          organization: param.partnerOrganizationId,
          action: {
            message: "Report Generation Completed.",
            data: null,
          },
          flowtype: param.flowType.type ? param.flowType.type : param.flowType,
          page: "CMMC2 Reports Page",
          client: param.clientOrgId,
          cset_assessment: param.ccAssessmentInfo.id,
        },
      },
    }).then((res: any) => {}).catch((err:any)=>{sentry_error_catch(err,setShowBackdrop,setFormState)})
  }, [param]);

  const onDownload = async (event: any, rowData: any) => {
    if (param.hasOwnProperty("ccAssessmentInfo")) {
      try{
      if (
        param.ccAssessmentInfo.assessment_Document ||
        param.ccAssessmentInfo.assessment_Document[0].url
      ) {
        setShowBackdrop(true);
        const lawUrl = param.ccAssessmentInfo.assessment_Document[0].url
          ? param.ccAssessmentInfo.assessment_Document[0].url.slice(1)
          : param.ccAssessmentInfo.assessment_Document.slice(1);
        const DocUrl = `${serverUrl}${lawUrl}`;
        await fetch(DocUrl, {
          method: "GET",
        })
          .then((res) => res.blob())
          .then((response) => {
            saveAs(
              response,
              param.assessmentInfo.assessmentName.indexOf("%") > -1
                ? param.assessmentInfo.assessmentName.slice(
                    0,
                    param.assessmentInfo.assessmentName.indexOf("%")
                  )
                : param.assessmentInfo.assessmentName + ".zip"
            );
            setShowBackdrop(false);
          })
          .catch((err:any) => {
            sentry_error_catch(err,setShowBackdrop,setFormState)
          });
      }
     }catch(err){
      showBoundary(err);
     }
    }
  };

  useEffect(() => {
    if (
      param !== undefined &&
      param.flowType !== undefined &&
      param.flowType.type === "Standard"
    ) {
      setStepper(
        client,
        standardStepperObject.reports.name,
        standardStepperObject.reports.value
      );
    }
  }, [param]);

  const onClickReports = (DocumentName: any) => {
    try{
    if (DocumentName === "excutiveSummaryReport") {
      history(routeConstant.CMMC2_EXECUTIVE_SUMMARY, { state: { param } });
    }
    if (DocumentName === "deficiencyReport") {
      history(routeConstant.CMMC2_DEFICIENCY_REPORT, { state: { param } });
    }
    if (DocumentName === "commentsAndReview") {
      history(routeConstant.CMMC2_COMMENT_AND_REVIEW_REPORT, {
        state: { param },
      });
    }
   }catch(err){
    showBoundary(err);
   }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleCancel = () => {
    try{
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      assessment_id: param.ccAssessmentInfo.assessment_id,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    history(routeConstant.ASSESSMENT_LIST, { state: { val } });
    }catch(err){
      showBoundary(err);
    }
  };

  window.addEventListener("popstate", function (event) {
    history(routeConstant.CLIENT);
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography component="h5" variant="h1">
            {"Reports"}
          </Typography>
          {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formState.errMessage}
              </Alert>
            ) : null}
          <Paper className={styles.paper}>
            {showBackdrop ? <SimpleBackdrop /> : null}
            <Grid container>
              <Grid item md={12} sm={6} className={styles.sal_levels}>
                <div>
                  <List
                    component="nav"
                    className={styles.reportList}
                    aria-label="main mailbox folders"
                  >
                    <ListItem
                      button
                      className={styles.executiveSummaryList}
                      onClick={(event) =>
                        onClickReports("excutiveSummaryReport")
                      }
                    >
                      <img
                        className={styles.EditIcon}
                        src={`${process.env.PUBLIC_URL}/icons/report-icon/executive-summary.png`}
                        alt={msgConstants.EXECUTIVE_SUMMARY}
                      />
                      <ListItemText
                        className={styles.reportListText}
                        primary={" CMMC 2.0 Executive Summary "}
                      />
                    </ListItem>
                    <ListItem
                      button
                      className={styles.siteSummaryList}
                      onClick={(event) => onClickReports("deficiencyReport")}
                    >
                      <img
                        className={styles.EditIcon}
                        src={`${process.env.PUBLIC_URL}/icons/report-icon/site-summary.png`}
                        alt={msgConstants.SITE_SUMMARY_REPORT}
                      />
                      <ListItemText
                        className={styles.reportListText}
                        primary={" CMMC 2.0 Deficiency Report "}
                      />
                    </ListItem>
                    <ListItem
                      button
                      className={styles.siteSummaryList}
                      onClick={(event) => onClickReports("commentsAndReview")}
                    >
                      <img
                        className={styles.EditIcon}
                        src={`${process.env.PUBLIC_URL}/icons/report-icon/site-summary.png`}
                        alt={msgConstants.COMMENT_AND_REVIEW}
                      />
                      <ListItemText
                        className={styles.reportListText}
                        primary={" CMMC 2.0 Comments and Marked for Review "}
                      />
                    </ListItem>
                  </List>
                </div>
              </Grid>
              <Grid item md={12} sm={6} className={styles.sal_levels}></Grid>
              <Grid item xs={12} sm={6} className={styles.sal_levels}></Grid>
              <Grid item xs={12} sm={6} className={styles.sal_levels}></Grid>
              <Grid item xs={12} sm={6} className={styles.sal_levels}></Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} className={styles.FooterActions}>
          <div className={styles.prevBtn}>
            {param &&
            param.hasOwnProperty("from") &&
            param.from === "view" ? null : (
              <></>
            )}
            <div className={styles.cancelButton}>
              <Button
                id="cancel-button"
                variant="contained"
                color="primary"
                onClick={handleCancel}
              >
                {"Done"}
              </Button>
            </div>
            <Button
              id="download-All-Report"
              variant="contained"
              color="primary"
              onClick={onDownload}
            >
              {"Download All Reports"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CMMC2Reports;
