import { gql } from "@apollo/client";

export const GET_QUESTION_DOCUMENT = gql`
query getQuestionDocument($assessment_id:Int!,$question_id:Int!,$featureType:ENUM_QUESTIONDOCUMENTS_FEATURE_TYPE!){
    questionDocuments( where: {assessment_id:$assessment_id,question_id:$question_id,feature_type:$featureType}){
      id
      flow_type
      practise
      assessment_id{
        id
        assessment_id
      }
      comment
      marked_for_review
      document_id{
        id
        name
        url
      }
      question_Text
      question_id
    }
  }
`
export const GET_DOCUMENT_BY_ASSESSMENT_ID = gql`
query getAllDocumentsByAssId($assId:Int,$featureType:ENUM_QUESTIONDOCUMENTS_FEATURE_TYPE!){
  questionDocuments(where:{assessment_id:$assId,feature_type:$featureType}){
    id
    question_id
    question_Text
    assessment_id{
      assessment_name
      id
    }
    comment
    marked_for_review
    flow_type
    practise
    document_id{
      id
      name
      url
    }
  }
}
`
export const GET_ALL_DOCUMENTS = gql`
query getAllDocuments($assId:Int,$featureType:ENUM_QUESTIONDOCUMENTS_FEATURE_TYPE!,$practise:String){
  questionDocuments(where:{feature_type:$featureType,assessment_id:$assId,practise_contains:$practise}){
    id
    question_id
    question_Text
    assessment_id{
      id 
      assessment_name
    }
    practise
    comment
    marked_for_review
    document_id{
      id
      url
    }
  }
}
`
