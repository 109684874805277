import React, { useState, useEffect } from "react";
import styles from "./AssessmentInfo.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AssessmentForm from "../AssessmentForm/AssessmentForm";
import Typography from "@mui/material/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../common/RouteConstants";
import * as msgConstants from "../../../common/MessageConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../config/index";
import TopStepper from "../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import stepper from "../../../common/csetStepperMenu.json";
import { setStepper } from "../../../common/SetStepper";
import { setActiveFormStep } from "../../../services/Data";
import { EXPSECONDS } from "../../../common/CSET";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_CSET_ASSESSMENT } from "../../../graphql/queries/CSETAssessment";
import crrStepper from "../../../common/csetCRRStepperMenu.json";
import rraStepper from "../../../common/csetRRAStepperMenu.json";
import logout from "../../../containers/Auth/Logout/Logout";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import {
  CREATE_CSET_ASSESSMENT,
  UPDATE_CSET_ASSESSMENT,
} from "../../../graphql/mutations/CSETAssessment";
import cmmcStepper from "../../../common/csetCMMCStepperMenu.json";
import { HISTORY } from "../../../graphql/mutations/AuditTrail";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";

export const AssessmentInfo: React.FC = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const enableBackdrop = () => {
    return false;
  };
  let parseParam:any;
  if(sessionStorage.getItem("param")){
    const paramObj:any = sessionStorage.getItem("param");
    parseParam = JSON.parse(paramObj);
  }
  const CurrentTimeStamp = new Date().getTime();
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [facilityName, setFacilityName] = useState<string>("");
  const stepperObject = stepper;
  const crrStepperObject = crrStepper;
  const rraStepperObject = rraStepper;
  const cmmcStepperObject = cmmcStepper;
  const contentType = msgConstants.CONTENT_TYPE;
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const RAuser: any = JSON.parse(sessionStorage.getItem("ra_user") || "");
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false; 
  const [openNextDialogBox, setOpenNextDialogBox] = useState<boolean>(false);
  const client = useApolloClient();
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [param, setParam] = useState<any>(
    location.state ? location.state[Object.keys(location.state)[0]] : parseParam
  );
  const [previousPageParams, setPreviousPageParams] = useState<any>(
    location.state ? location.state[Object.keys(location.state)[0]] : parseParam
  );
  const [demographicsObj, setDemographicsObj] = useState<any>({});
  const [assetvalue, setAssetvalue] = useState<any>("");
  const [assessmentTypeData, setAssessmentTypeData] = useState<any>({});
  const [assessmentDetailsObj, setAssessmentDetailsObj] = useState({
    assessmentName: "",
    id: "",
  });
  const [totalnumberScans, setTotalnumberScans] = useState<any>(0);
  const [scanPrice, setScanPrice] = useState<any>("");
  const [assResponse, setAssResponse] = useState<any>({});
  const [flowType, setFlowType] = useState<any>({});
  const [stepperObj, setStepperObj] = useState<any>({ path: "" });
  const [updateAssessement] = useMutation(UPDATE_CSET_ASSESSMENT);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [csetAssessmentType,setCsetAssessmentType] = useState<any>();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [saveAssessementInfo] = useMutation(CREATE_CSET_ASSESSMENT);
  const [auditTrail] = useMutation(HISTORY);

  const headerObj = {
    "Content-Type": contentType,
    Authorization: authToken,
  };

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    showDialog: false,
    errMessage: "",
  });
  console.log("param",parseParam);
  console.log("param",param);
  useEffect(() => {
    setParam(location.state ? location.state[Object.keys(location.state)[0]] : parseParam);
    setPreviousPageParams(location.state ? location?.state[Object.keys(location?.state)[0]] : parseParam);
    if (location.state && location?.state[Object.keys(location?.state)[0]].assessmentInfo.id || parseParam) {
      getTokenForAssessment();
    }
    setShowBackdrop(false);
  }, []);

  useEffect(() => {
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "Custom"
    ) {
      setStepper(
        client,
        stepperObject.assessmentInformation.name,
        stepperObject.assessmentInformation.value
      );
    }
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "CRR"
    ) {
      setStepper(
        client,
        crrStepperObject.assessmentInformation.name,
        crrStepperObject.assessmentInformation.value
      );
    }
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "RRA"
    ) {
      setStepper(
        client,
        rraStepperObject.assessmentInformation.name,
        rraStepperObject.assessmentInformation.value
      );
    }
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "CMMC2"
    ) {
      setStepper(
        client,
        cmmcStepperObject.assessmentInformation.name,
        cmmcStepperObject.assessmentInformation.value
      );
    }
    setFlowType(param?.flowType.type);
    setTotalnumberScans(param?.totalNumberScans);
    setScanPrice(param?.ScanPrice);
    setActiveFormStep(0);
  }, [param]);

  useEffect(() => {
    const assmntName = assessmentDetailsObj.assessmentName.trim();
    if (assmntName && assmntName !== " " && assmntName !== "New Assessment") {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [assessmentDetailsObj.assessmentName]);

  const getTokenForAssessment = async () => {
    await fetch(
      `${CSET_URL}api/auth/token?assessmentId=${param.assessmentInfo.id}&expSeconds=${EXPSECONDS}`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        getAssessmentDetails(data.token);
      })
      .catch((err:any) => {
        setShowBackdrop(false);
        if(!is_link_assessment){
          handleCancel();
        }else{
          sentry_error_catch(err,setShowBackdrop,setFormState)
        }
      });
  };

  const getTokenForCreatedAssessment = async (assId?: any) => {
    await fetch(
      `${CSET_URL}api/auth/token?assessmentId=${assId}&expSeconds=${EXPSECONDS}`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        getCreatedAssessmentDetails(data.token);
      })
      .catch((err:any) => {
        setShowBackdrop(false);
        if(!is_link_assessment){
          handleCancel();
        }else{
          sentry_error_catch(err,setShowBackdrop,setFormState)
        }
      });
  };

  const [getAssessment, { data: Adata }] = useLazyQuery(GET_CSET_ASSESSMENT, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (data: any) => {
      setShowBackdrop(false);
      setFlowType(data.csetAssessments[0].assessment_type);
      setAssetvalue(data.csetAssessments[0].assetValue);
      if (data.csetAssessments[0].cset_firm_id) {
        setFormState((formStateObj) => ({
          ...formStateObj,
          isSuccess: false,
          showDialog: true,
          isDelete: false,
          isUpdate: false,
          isFailed: false,
        }));
      }
      const assInfo: any = assResponse;
      assInfo["assessmentName"] = data.csetAssessments[0].assessment_name;
      assInfo["creatorName"] = param.clientInfo.name;
      assInfo["assetValue"] = data.csetAssessments[0].assetValue;
      assInfo["stateProvRegion"] = data.csetAssessments[0].stateProvRegion;
      assInfo["cityOrSiteName"] = data.csetAssessments[0].cityOrSiteName;
      assInfo["facilityName"] = data.csetAssessments[0].facility_name;
      setCsetAssessmentType(data.csetAssessments[0]?.cset_assessment_type?.id);
      if (
        data.csetAssessments[0].assessment_type !== "Custom" &&
        data.csetAssessments[0].assessment_type !== "Standard"
      ) {
        selectMaturityModel(authToken, data.csetAssessments[0].assessment_type);
        assInfo["useMaturity"] = true;
      }
      setAssessmentDetailsObj(assInfo);
      setShowBackdrop(false);
    },
    onError: (err:any) => {
      setShowBackdrop(false);
      if(!is_link_assessment){
        handleCancel();
      }else{
        sentry_error_catch(err,setShowBackdrop,setFormState)
      }
    },
  });

  const [getCreatedAssessment] = useLazyQuery(
    GET_CSET_ASSESSMENT,
    {
      fetchPolicy: msgConstants.networkFetchPolicy,
      onCompleted: (data) => {
        setFlowType(data.csetAssessments[0].assessment_type);
        setAssetvalue(data.csetAssessments[0].assetValue);
        if (data.csetAssessments[0].cset_firm_id) {
          setFormState((formStateObj) => ({
            ...formStateObj,
            isSuccess: false,
            showDialog: true,
            isDelete: false,
            isUpdate: false,
            isFailed: false,
          }));
        }
        const assInfo: any = assResponse;
        data.csetAssessments[0].assessment_name !== null
          ? (assInfo["assessmentName"] =
              data.csetAssessments[0].assessment_name.slice(
                0,
                data.csetAssessments[0].assessment_name.indexOf("%")
              ))
          : (assInfo["assessmentName"] = assessmentDetailsObj.assessmentName);
        assInfo["creatorName"] = param.clientInfo.name;
        assInfo["assetValue"] = data.csetAssessments[0].assetValue;
        assInfo["stateProvRegion"] = data.csetAssessments[0].stateProvRegion
          ? data.csetAssessments[0].stateProvRegion
          : state;
        assInfo["cityOrSiteName"] = data.csetAssessments[0].cityOrSiteName
          ? data.csetAssessments[0].cityOrSiteName
          : city;
        assInfo["facilityName"] = data.csetAssessments[0].facility_name
          ? data.csetAssessments[0].facility_name
          : facilityName;
        setCsetAssessmentType(data.csetAssessments[0]?.cset_assessment_type?.id);
        if (
          data.csetAssessments[0].assessment_type !== "Custom" &&
          data.csetAssessments[0].assessment_type !== "Standard"
        ) {
          selectMaturityModel(
            authToken,
            data.csetAssessments[0].assessment_type
          );
          assInfo["useMaturity"] = true;
        }
        setAssessmentDetailsObj(assInfo);

        handleSubmit();
      },
      onError: (err:any) => {
        setShowBackdrop(false);
        if(!is_link_assessment){
          handleCancel();
        }else{
          sentry_error_catch(err,setShowBackdrop,setFormState)
        }
      },
    }
  );

  const selectMaturityModel = async (val: any, flow: any) => {
    await fetch(`${CSET_URL}api/MaturityModel?modelName=${flow}`, {
      method: "POST",
      headers: {
        "Content-Type": contentType,
        Authorization: val,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const assInfo: any = assResponse;
        assInfo["maturityModel"] = data;
        setAssessmentTypeData(data);
      })
      .catch((err:any)=>{
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const getAssessmentDetails = async (val: any) => {
    await fetch(`${CSET_URL}api/assessmentdetail`, {
      method: "GET",
      headers: {
        "Content-Type": contentType,
        Authorization: val,
      },
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        setAssResponse(data);
        if (val) {
          sessionStorage.setItem("ra_token", val);
        }
        setParam((paramData: any) => {
          return {
            ...paramData,
            assessmentName: data.typeTitle,
            assessmentDescription: data?.typeDescription
              ?.replaceAll("<p>", "")
              .replaceAll("</p>", ""),
          };
        });
        getAssessment({
          variables: {
            where: {
              assessment_id: data.id,
            },
          },
        });
        getDemographicsInfo(data.id, val);
      })
      .catch((err:any)=>{
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const getCreatedAssessmentDetails = async (val: any) => {
    await fetch(`${CSET_URL}api/assessmentdetail`, {
      method: "GET",
      headers: {
        "Content-Type": contentType,
        Authorization: val,
      },
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        setAssResponse(data);
        if (val) {
          sessionStorage.setItem("ra_token", val);
        }
        getCreatedAssessment({
          variables: {
            where: {
              assessment_id: data.id,
            },
          },
        });
        getDemographicsInfo(data.id, val);
      })
      .catch((err:any)=>{
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const getDemographicsInfo = async (id: any, token: any) => {
    await fetch(`${CSET_URL}api/Demographics/?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": contentType,
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDemographicsObj(data);
      })
      .catch((err:any)=>{
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const assessmentHandleChange = (event: any) => {
    if (event.hasOwnProperty("clientInfo")) {
      setParam(event);
    }
    if (event.hasOwnProperty("Id")) {
      setAssessmentDetailsObj(event);
    }
    if (event.hasOwnProperty("assessmentId")) {
      setDemographicsObj(event);
    }
    if (event.hasOwnProperty("cityOrSiteName")) {
      setCity(event.cityOrSiteName);
    }
    if (event.hasOwnProperty("stateProvRegion")) {
      setState(event.stateProvRegion);
    }
    if (event.hasOwnProperty("type")) {
      setFlowType(event);
    }
    if (event.hasOwnProperty("assessmentName")) {
      assessmentDetailsObj.assessmentName = event.assessmentName;
      const assmntName = assessmentDetailsObj.assessmentName;
      if (assmntName && assmntName !== "New Assessment") {
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
    }
    if (event.hasOwnProperty("assetValue")) {
      setAssetvalue(event.assetValue);
    }
    if (event.hasOwnProperty("FacilityName")) {
      setFacilityName(event.FacilityName);
    }
  };

  const handleCatch = (message: any) => {
    setShowBackdrop(false);
    let error: any = message;
    if (
      error?.includes("Duplicate entry") ||
      error?.includes("duplicate key")
    ) {
      error = "Assessment Name already present.";
    } else {
      error = msgConstants.ERROR_MESSAGE
      Sentry.captureException(message)
      console.log(message)
    }
    setFormState((formStateObj) => ({
      ...formStateObj,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: error,
    }));
  };

  const handleSubmit = async (values?: any) => {
    const obj = {
      "Content-Type": contentType,
      Authorization: authToken,
    };
    demographicsObj["assetValue"] = parseInt(assetvalue);
    if (authToken) {
      setShowBackdrop(true);
      if (flowType.type === "Standard" || flowType === "Standard") {
        submitStandardFlow(obj);
      } else {
        submitCustomFlow(obj, values);
      }
    } else {
      logout();
    }
  };

  const submitStandardFlow = async (obj: any) => {
    console.log("HII",)
    updateAssessement({
      variables: {
        id: param?.ccAssessmentInfo.id,
        assessment_type: param.flowType.type,
        assessment_name:
          assessmentDetailsObj.assessmentName.indexOf("%") > -1
            ? assessmentDetailsObj.assessmentName
            : `${assessmentDetailsObj.assessmentName}%%${CurrentTimeStamp}`,
        assetValue: demographicsObj.assetValue
          ? demographicsObj.assetValue?.toString()
          : assetvalue?.toString(),
        cityOrSiteName: city ? city : Adata?.csetAssessments[0].cityOrSiteName,
        stateProvRegion: state
          ? state
          : Adata?.csetAssessments[0].stateProvRegion,
        cset_assessment_type : csetAssessmentType ? csetAssessmentType :null,
      },
    })
      .then(async () => {
        if (assessmentDetailsObj.id) {
          demographicsObj["assetValue"] = parseInt(assetvalue);
          await fetch(`${CSET_URL}api/Demographics/`, {
            method: "POST",
            headers: obj,
            body: JSON.stringify(demographicsObj),
          }).catch((err:any)=>{
            sentry_error_catch(err,setShowBackdrop,setFormState)
          })
          const salObj = {
            ALevel: "Low",
            AssessmentName: null,
            CLevel: "Low",
            ILevel: "Low",
            Last_Sal_Determination_Type: "Simple",
            SelectedSALOverride: false,
            Selected_Sal_Level: "Low",
            Sort_Set_Name: null,
          };
          await fetch(`${CSET_URL}api/SAL`, {
            method: "POST",
            headers: headerObj,
            body: JSON.stringify(salObj),
          }).catch((err:any)=>{
            sentry_error_catch(err,setShowBackdrop,setFormState)
          })

          await fetch(`${CSET_URL}api/assessmentdetail`, {
            method: "POST",
            headers: headerObj,
            body: JSON.stringify(assessmentDetailsObj),
          })
            .then((res: any) => res.json())
            .then((response: any) => {
              handleNext();
            }).catch((err:any)=>{
              sentry_error_catch(err,setShowBackdrop,setFormState)
            })
        }
      })
      .catch((err: any) => {
        handleCatch(err.message);
      });
  };

  const submitCustomFlow = async (obj: any, values?: any) => {
    console.log("HII1",Adata,param)
    updateAssessement({
      variables: {
        id: values ? values.ccAssessmentInfo.id : param.ccAssessmentInfo.id,
        assessment_name:
          assessmentDetailsObj.assessmentName.indexOf("%") > -1
            ? assessmentDetailsObj.assessmentName
            : `${assessmentDetailsObj.assessmentName}%%${CurrentTimeStamp}`,
        assessment_type: param.flowType.type,
        cset_firm_id: null,
        assetValue: demographicsObj.assetValue
          ? demographicsObj.assetValue?.toString()
          : assetvalue?.toString(),
        cityOrSiteName: city ? city : Adata?.csetAssessments[0].cityOrSiteName,
        stateProvRegion: state
          ? state
          : Adata?.csetAssessments[0].stateProvRegion,
        cset_assessment_type : csetAssessmentType ? csetAssessmentType :null,
      },
    })
      .then(async () => {
        if (assessmentDetailsObj.id) {
          demographicsObj["assetValue"] = parseInt(assetvalue);
          await fetch(`${CSET_URL}api/Demographics/`, {
            method: "POST",
            headers: obj,
            body: JSON.stringify(demographicsObj),
          });
          await fetch(`${CSET_URL}api/assessmentdetail`, {
            method: "POST",
            headers: headerObj,
            body: JSON.stringify(assessmentDetailsObj),
          })
            .then((res: any) => res.json())
            .then((response: any) => {
              handleNext();
            });
        }
      })
      .catch((err: any) => {
        handleCatch(err.message);
      });
  };
  console.log("param", param);
  console.log("pg partner Id-",param.pgPartnerId);
  const handleNext = async () => {
    setShowBackdrop(false);
    const paramVal: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      assessmentInfo: assResponse,
      ccAssessmentInfo: param.ccAssessmentInfo,
      pgPartnerId: param.pgPartnerId,
      assessmentCreate: param.assessmentCreate,
      assessmentUpdate: param.assessmentUpdate,
      pgPartnerUserId: param.pgPartnerUserId,
      flowType: {
        type: flowType.type ? flowType.type : flowType,
      },
      assetValue: demographicsObj.assetValue
        ? demographicsObj.assetValue
        : parseInt(assetvalue),
      assessmentGUID: param.assessmentGUID,
      assessmentDescription: param.assessmentDescription,
      assessmentName: param.assessmentName,
      groupTitle: param.groupTitle,
      individualId: previousPageParams.individualId,
      partnerOrganizationId: previousPageParams.partnerOrganizationId,
      clientOrgId: previousPageParams.clientOrgId,
    };

    if (flowType === "Standard" || flowType.type === "Standard") {
      history(routeConstant.FIRM_TYPE, { state: { paramVal } });
    }
    if (flowType === "Custom" || flowType.type === "Custom") {
      history(routeConstant.SECURITY_ASSURANCE_LEVEL, { state: { paramVal } });
    }
    if (flowType === "CMMC2" || flowType.type === "CMMC2") {
      history(routeConstant.CMMC2_LEVEL, { state: { paramVal } });
    }
    if (flowType === "CRR" || flowType.type === "CRR") {
      history(routeConstant.ASSESSMENT_DEMOGRAPHICS, { state: { paramVal } });
    }
    if (flowType === "RRA" || flowType.type === "RRA") {
      history(routeConstant.ASSESSMENT_DEMOGRAPHICS, { state: { paramVal } });
    }
  };

  const NavTo = () => {
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    history(routeConstant.ASSESSMENT_LIST, { state: { val } });
  };

  const handleCancel = async () => {
    try{
    if (param.ccAssessmentInfo.assessment_id === undefined) {
      NavTo();
      setShowBackdrop(false);
    } else {
      closeDialogBox();
      const deleteObj = {
        deleteAssessment: [{ delete: true, partnerUserId: RAuser?.user?.id }],
      };
      updateAssessement({
        variables: {
          id: param.ccAssessmentInfo.id,
          assessment_type: flowType.type,
          deleteData: deleteObj,
        },
      })
        .then((response: any) => {
          NavTo();
          setShowBackdrop(false);
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setShowBackdrop,setFormState)
        });
    }
    }catch(err){
      showBoundary(err);
    }
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    handleSubmit();
  };

  const handleBack = () => {
    try{
    if (flowType === "Standard" || flowType.type === "Standard") {
      history(routeConstant.STANDARD_TUTORIAL, { state: { param } });
    }
    if (flowType === "Custom" || flowType.type === "Custom") {
      history(routeConstant.STANDARD_TUTORIAL, { state: { param } });
    }
    if (flowType === "CMMC2" || flowType.type === "CMMC2") {
      history(routeConstant.CMMC_TUTORIAL, { state: { param } });
    }
    if (flowType === "CRR" || flowType.type === "CRR") {
      history(routeConstant.CRR_TUTORIAL, { state: { param } });
    }
    if (flowType === "RRA" || flowType.type === "RRA") {
      history(routeConstant.RRA_TUTORIAL, { state: { param } });
    }
   }catch(err){
    showBoundary(err);
   }
  };

  const closeDialogBox = () => {
    setShowBackdrop(false);
    setOpenDialogBox(false);
    setOpenNextDialogBox(false);
  };

  const handleconfirm = async () => {
    auditTrail({
      variables: {
        auditTrailInput: {
          individual: param.individualId,
          organization: param.partnerOrganizationId,
          action: { message: "Clicked on Okay Button to Create Assessment" },
          flowtype: flowType.type ? flowType.type : flowType,
          page: "Assessment Info",
          client: param.clientOrgId,
        },
      },
    }).catch((err:any)=>{
      sentry_error_catch(err,setShowBackdrop,setFormState)
    });
    closeDialogBox();
    setShowBackdrop(true);
    createNewAssessment();
  };

  const createNewAssessment = async () => {
    try {
      setShowBackdrop(true);
      await fetch(
        `${CSET_URL}api/createassessment/gallery?workflow=BASE&galleryGuid=${param.assessmentGUID}`,
        {
          method: "GET",
          headers: headerObj,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          const val = {
            assessmentInfo: data,
            clientInfo: param.clientInfo,
            csetUserInfo: param.csetUserInfo,
            pgPartnerId: param.pgPartnerId,
            pgPartnerUserId: param.pgPartnerUserId,
            ccAssessmentInfo: param.ccAssessmentInfo,
            assessmentCreate: true,
            assessmentUpdate: false,
            groupTitle: param.groupTitle,
            assessmentGUID: param.assessmentGUID,
            assessmentDescription: param.assessmentDescription,
            assessmentName: param.assessmentName,
            flowType: {
              type: flowType.type ? flowType.type : flowType,
            },
          };
          saveAssessementInfo({
            variables: {
              contact_id: param.clientInfo.clientId,
              assessment_id: data.id,
              assessment_name: `${assessmentDetailsObj.assessmentName}%%${CurrentTimeStamp}`,
              assessment_type: flowType.type ? flowType.type : flowType,
              cset_firm_id: null,
              assetValue: demographicsObj.assetValue
                ? demographicsObj.assetValue?.toString()
                : assetvalue?.toString(),
              cityOrSiteName: city,
              stateProvRegion: state,
              facility_name: facilityName,
              user_id: RAuser?.user?.id,
              cset_assessment_type:parseInt(param.csetAssessmentTypeId) ? parseInt(param.csetAssessmentTypeId) : null,
            },
          })
            .then((res) => {
              val["ccAssessmentInfo"] =
                res.data.createCsetAssessment.csetAssessment;
              setParam(val);
              setActiveFormStep(0);
              getTokenForCreatedAssessment(data.id);
            })
            .catch((err:any)=>{
              sentry_error_catch(err,setShowBackdrop,setFormState)
            });
        }).catch((err:any)=>{
          sentry_error_catch(err,setShowBackdrop,setFormState)
        });
    } catch(err:any){
      sentry_error_catch(err,setShowBackdrop,setFormState)
    };
  };

  const handleFormData = () => {
    try{
    if (param.assessmentInfo.id) {
      setOpenNextDialogBox(false);
      param.assessmentCreate = false;
      param.assessmentUpdate = true;
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: param.individualId,
            organization: param.partnerOrganizationId,
            action: { message: "Clicked on Next Button to Update Assessment" },
            flowtype: flowType.type ? flowType.type : flowType,
            page: "Assessment Info",
            client: param.clientOrgId,
          },
        },
      }).catch((err:any)=>{
      sentry_error_catch(err,setShowBackdrop,setFormState)
     });
      handleSubmit();
    } else {
      setOpenNextDialogBox(true);
    }
   }catch(err){
    showBoundary(err);
   }
  };

  const DeleteHandler = () => {
    auditTrail({
      variables: {
        auditTrailInput: {
          individual: param.individualId,
          organization: param.partnerOrganizationId,
          cset_assessment: param.ccAssessmentInfo.id,
          action: {
            message: `User Clicked on Delete Button in Assessment Info Page.`,
          },
          flowtype: flowType.type ? flowType.type : flowType,
          page: "Assessment Info",
          client: param.clientOrgId,
        },
      },
    }).then((res: any) => {
      setOpenDialogBox(true);
    }).catch((err:any) => {
      Sentry.captureException(err)
    });
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Risk Assessment for{" "}
        {param
          ? param.clientInfo === undefined
            ? param?.assessmentInfo?.CreatorName
            : param?.clientInfo?.name
          : null}
      </Typography>
      <DialogBox
        open={openDialogBox}
        handleOk={handleCancel}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>
            Are you sure you want to remove{" "}
            {/* {param.assessmentInfo?.assessmentName}? */}
          </p>
        </div>
      </DialogBox>
      <DialogBox
        open={openNextDialogBox}
        handleOk={handleconfirm}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.CreditMainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxContext}>
          <p>
            {totalnumberScans > 0
              ? msgConstants.CREDIT_DEDUCTION
              : `${msgConstants.PRICING} ${scanPrice}.`}
          </p>
        </div>
      </DialogBox>
      <Grid container spacing={0} className={styles.FooterGridWrap}>
        <Grid item xs={12} className={styles.FooterActions}>
          <div className={styles.topstepperButons}>
            {!is_link_assessment && (<div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}>
                {"<< Previous"}
              </Button>
            </div>)}
            <div className={styles.topStepBtnWrap}>
              <TopStepper
                param={param}
                handleSave={callingFromTopStepper}
                obj={stepperObj}
                submitDisabled={false}
              />
            </div>
            <div className={styles.nextBtn}>
              <Button
                id="next-button"
                variant="contained"
                color="primary"
                onClick={handleFormData}
                disabled={submitDisabled}>
                {"Next >>"}
              </Button>
            </div>
          </div>
          {!is_link_assessment && (<div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={DeleteHandler}>
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>)}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={styles.paper}>
            <React.Fragment>
              {showBackdrop ? <SimpleBackdrop /> : null}
              {authToken !== "" && demographicsObj ? (
                <AssessmentForm
                  formState={formState}
                  propsFlowType={flowType}
                  propsData={param}
                  authToken={authToken}
                  assessmentObj={assessmentDetailsObj}
                  currentAssessmentData={Adata?.csetAssessments[0]}
                  demographicsObj={demographicsObj}
                  handleChange={assessmentHandleChange}
                  assessmentTypeObj={assessmentTypeData}
                />
              ) : null}
            </React.Fragment>
          </Paper>
        </Grid>
      </Grid>
      {enableBackdrop() ? null : <Grid container spacing={3}></Grid>}
    </React.Fragment>
  );
};

export default AssessmentInfo;
