import { Button, CssBaseline, Grid, Paper, Typography, IconButton } from "@mui/material";
import { HorizontalBar } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import styles from "./RRADeficiencyReport.module.css";
import GetAppIcon from "@mui/icons-material/GetApp";
import * as msgConstants from "../../../../../common/MessageConstants";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import MaterialTable from "../../../../../components/UI/Table/MaterialTable";
import logout from "../../../../Auth/Logout/Logout";
import { saveAs } from "file-saver";
import autoTable from "jspdf-autotable";
import * as docx from "docx";
import { jsPDF } from "jspdf";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../../../components/UI/Alert/Alert";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface DeficiencyProps {
  from?: string;
  props?: any;
  data?: any;
  handleChange?: any;
  location?: any;
  partnerLogo?: any;
  clientLogo?: any;
  partnerImageDimension?: any;
  clientImageDimension?: any;
}

export const RRADeficiencyReport: React.FC<DeficiencyProps> = ({
  from,
  data,
  props,
  handleChange,
  // location,
  partnerLogo,
  clientLogo,
  partnerImageDimension,
  clientImageDimension,
}: DeficiencyProps) => {
  const history = useNavigate();
  const location = useLocation();
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const componentRef = React.useRef<HTMLHeadingElement>(null);
  const [basicList, setBasicList] = useState<any>([]);
  const [advancedList, setAdvancedList] = useState<any>([]);
  const [intermediateList, setIntermediateList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState<any>(
    location.state[Object.keys(location.state)[0]]
  );
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");
  const [partnerImage, setPartnerImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const preparedForBy = "preparedFor/By";
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [suggestedAreaOfImprovement, setSuggestedAreaOfImprovement] = useState({});
  const chartRef1 = React.useRef<any>(null);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const column = [
    { title: "Practice", key: "question_Title" },
    { title: "Question", key: "question_Text" },
    { title: "Response", key: "answer" },
  ];

  useEffect(() => {
    const controller = new AbortController();
  
    if (props) {
      setParam(props);
    } else {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
    getDeficiencyData();
    getRRADetails();
    return () => controller.abort();
  }, []);

  useEffect(() => {
     const controller = new AbortController();
    if (param.clientInfo) {
      GetOrganizationDataOfPartner({
        variables: {
          where: {
            contact_id: param?.clientInfo.partnerId,
          },
        },
      });
      GetOrganizationDataOfClient({
        variables: {
          where: {
            id: param?.clientInfo.clientOrgId,
            subtype: "Client",
          },
        },
      });
    }
      return () => controller.abort();
  }, [param, props]);

  const options = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getRRADetails = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/reports/rradetail`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          createAnswerDistribByGoal(data);
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const createAnswerDistribByGoal = (r: any) => {
    const goalList: any = [];
    r.rraSummaryByGoal.forEach((element: any) => {
      let goal: any = goalList.find((x: any) => x.name === element.title);
      if (!goal) {
        goal = {
          name: element.title,
          series: [
            { name: "Yes", value: 0 },
            { name: "No", value: 0 },
            { name: "Unanswered", value: 0 },
          ],
        };
        goalList.push(goal);
      }

      const p = goal.series.find(
        (x: any) => x.name === element.answer_Full_Name
      );
      p.value = element.percent;
    });
    createTopRankedGoals(goalList);
  };

  const createTopRankedGoals = (data: any) => {
    const goalList: any = [];
    data.forEach((element: any) => {
      const yesPercent = element.series.find(
        (x: any) => x.name === "Yes"
      ).value;
      const goal = {
        name: element.name,
        value: 100 - Math.round(yesPercent),
      };
      goalList.push(goal);
    });

    goalList.sort((a: any, b: any) => {
      return b.value - a.value;
    });
    SuggestedAreaOfImprovement(goalList);
  };

  const SuggestedAreaOfImprovement = (data: any) => {
    const suggestedImprovement: any = {};
    suggestedImprovement.labels = [];
    suggestedImprovement.datasets = [];
    const Improvementds: any = {
      label: "",
      backgroundColor: "#DC3545",
      data: [],
    };
    suggestedImprovement.datasets.push(Improvementds);
    data.forEach((element: any) => {
      suggestedImprovement.labels.push(element.name);

      Improvementds.data.push(element.value);
    });
    setSuggestedAreaOfImprovement(suggestedImprovement);
  };

  const getDeficiencyData = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/getMaturityDeficiencyList?maturity=RRA`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          createDeficiencesData(data.deficienciesList);
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const getStringLevel = (levelNumber: number) => {
    //this should come from db eventually.
    const levelsList: any = {
      11: "Basic",
      12: "Intermediate",
      13: "Advanced",
    };
    return levelsList[levelNumber];
  };

  const createDeficiencesData = (deficienciesList: any) => {
    // sort the deficiencies by maturity level
    const basicList: any = [];
    const intermediateList: any = [];
    const advancedList: any = [];

    deficienciesList.forEach((element: any) => {
      const level = getStringLevel(element.mat.maturity_Level_Id);
      element.mat.question_Text
        .replaceAll(/(<([^>]+)>)/gi, " ")
        .replace(/&#10;/gi, "");
      switch (level) {
        case "Basic":
          const Basobj: any = {};
          Basobj["question_Title"] = element.mat.question_Title;
          Basobj["question_Text"] = element.mat.question_Text
            .replace(/&#8220;/gi, "")
            .replace(/&#8221;/gi, "")
            .replace(/&#8217;/gi, "");
          Basobj["answer"] =
            element.answer.answer_Text === "N" ? "No" : "Unanswered ";
          basicList.push(Basobj);
          break;
        case "Intermediate":
          const Inobj: any = {};
          Inobj["question_Title"] = element.mat.question_Title;
          Inobj["question_Text"] = element.mat.question_Text
            .replace(/&#8220;/gi, "")
            .replace(/&#8221;/gi, "")
            .replace(/&#8217;/gi, "");
          Inobj["answer"] =
            element.answer.answer_Text === "N" ? "No" : "Unanswered ";
          intermediateList.push(Inobj);
          break;
        case "Advanced":
          const Adobj: any = {};
          Adobj["question_Title"] = element.mat.question_Title;
          Adobj["question_Text"] = element.mat.question_Text
            .replace(/&#8220;/gi, "")
            .replace(/&#8221;/gi, "")
            .replace(/&#8217;/gi, "");
          Adobj["answer"] =
            element.answer.answer_Text === "N" ? "No" : "Unanswered ";
          advancedList.push(Adobj);
          break;
      }
    });
    setBasicList(basicList);
    setAdvancedList(advancedList);
    setIntermediateList(intermediateList);
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param?.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });
  const getBannerImages = async () => {
    setLoading(true);
    await fetch(`${process.env.PUBLIC_URL}/images/ExecutiveSummary.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error) => {
        setLoading(false);
        console.log("__Error", error);
        Sentry.captureException(error)
      });
   await fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        setLoading(false);
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData.logo && clientData.logo[0]) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
       await fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setCPAImage(base64data);
            };
          })
          .catch((error) => {
            setLoading(false);
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
      if (partnerData.logo && partnerData.logo[0]) {
        const partnerLogoUrl = partnerData.logo[0].url.slice(1);
       await fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error) => {
            setLoading(false);
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (from && from === "result" && 
      bannerImage &&
      partnerData.contact_id &&
      basicList &&
      advancedList &&
      cpaImage &&
      partnerImage &&
      intermediateList) {
        generate();
      }
  },[bannerImage &&
      partnerData.contact_id &&
      basicList &&
      advancedList &&
      cpaImage &&
      partnerImage &&
      intermediateList]);

  useEffect(() => {
    const controller = new AbortController();
    getBannerImages();
    return () => controller.abort();
  }, [partnerData, clientData]);

  const handleBack = () => {
    history(routeConstant.RRA_REPORTS, { state: { param } });
  };

  const generateTableHeaderCell = (text: string) => {
    return new docx.TableCell({
      children: [new docx.Paragraph({ text, style: "TableHeading" })],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "#d9d2e9",
        color: "auto",
      },
      verticalAlign: docx.VerticalAlign.CENTER,
    });
  };

  const generateTableIndividualCell = (text: string) => {
    let t = text;
    if (typeof text === "number") {
      t = String(text);
    }
    return new docx.TableCell({
      children: [
        new docx.Paragraph({
          text: t,
          heading: docx.HeadingLevel.HEADING_3,
        }),
      ],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "fcfaff",
        color: "auto",
      },
    });
  };

  const generateTableCells = (data: any, columns: any[]) => {
    let tableRows: any[] = [];
    tableRows = columns.map((c) => {
      return generateTableIndividualCell(data[c.key]);
    });
    return new docx.TableRow({
      children: [...tableRows],
    });
  };

  const generateTableHeaderRow = (columns: any[]) => {
    let tableColumns: any[] = [];
    tableColumns = columns.map((c) => {
      return generateTableHeaderCell(c.title);
    });
    return new docx.TableRow({
      children: [...tableColumns],
    });
  };

  const generateFinalTable = (data: any[], columns: any[]) => {
    const rows: any[] = data.map((d) => {
      return generateTableCells(d, columns);
    });
    return new docx.Table({
      rows: [generateTableHeaderRow(columns), ...rows],
    });
  };

  const BasicDeficiencyTable = generateFinalTable(basicList, column);
  const AdvancedDeficiencyTable = generateFinalTable(advancedList, column);
  const IntermediateDeficiencyTable = generateFinalTable(
    intermediateList,
    column
  );

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };

  const generate = async () => {
    console.log("cpaImage",cpaImage);
    console.log("partnerImage",partnerImage)
      console.log("__Generate");
      setLoading(true);
      const doc = new docx.Document({
        numbering: {
          config: [
            {
              reference: "top_categories",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
            {
              reference: "questions",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
          ],
        },
        styles: {
          default: {
            heading1: {
              run: {
                font: "Helvetica",
                size: 36,
                bold: true,
                color: "#4b0079",
              },
              paragraph: {
                alignment: docx.AlignmentType.LEFT,
                spacing: { line: 340 },
              },
            },
            heading2: {
              run: {
                font: "Helvetica",
                size: 30,
                bold: true,
                color: "#0b4785",
              },
              paragraph: {
                spacing: { line: 340 },
              },
            },
            heading3: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: { line: 276 },
              },
            },
            heading4: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                alignment: docx.AlignmentType.JUSTIFIED,
              },
            },
          },
          paragraphStyles: [
            {
              id: "normalPara",
              name: "Normal Para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: {
                  line: 276,
                  before: 20 * 72 * 0.1,
                  after: 20 * 72 * 0.05,
                },
                rightTabStop: docx.TabStopPosition.MAX,
                leftTabStop: 453.543307087,
              },
            },
            {
              id: "mainHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 80,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "date",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 40,
                bold: true,
              },
            },
            {
              id: "bulletText",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "customFontCng",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "miniHeader",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 24,
                bold: true,
              },
            },
            {
              id: "TableHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "BoldPara",
              name: "Normal Para3",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                bold: true,
              },
            },
            {
              id: preparedForBy,
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 20,
                bold: true,
              },
            },
            {
              id: "clientPartner",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
          ],
        },
      });
      const exeSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
      const defaultcpaLogo = defaultCPAImage
        ? defaultCPAImage.split(",")[1]
        : "";
      const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
      const msplogo = partnerImage
        ? partnerImage.split(",")[1]
        : defaultcpaLogo;
      const imageBase64Data1 = chartRef1?.current.chartInstance.toBase64Image();
      const thirdchart = imageBase64Data1?.split(",")[1];
      const image1 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(thirdchart), (c) => c.charCodeAt(0)),
        600,
        350
      );

      const image5 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(exeSummaryLogo), (c) => c.charCodeAt(0)),
        600,
        350
      );

      const image6 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
        fitImg(50, cpaImageDimensions),
        50
      );

      const image7 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50
      );
      const footerLogo = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50,
        {
          floating: {
            horizontalPosition: {
              align: docx.HorizontalPositionAlign.RIGHT,
            },
            verticalPosition: {
              align: docx.VerticalPositionAlign.BOTTOM,
            },
          },
        }
      );
      const bannertable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared for",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),

                  new docx.Paragraph(image6),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: param?.clientInfo.name,
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.2),
                  right: docx.convertInchesToTwip(1.8),
                },
                columnSpan: 5,
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },

                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared By",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph(image7),
                  new docx.Paragraph({
                    text: "",
                  }),
                  new docx.Paragraph({
                    text: partnerData.contact_id?.name,
                    style: "clientPartner",
                  }),
                  new docx.Paragraph({
                    text: "",
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.5),
                  right: docx.convertInchesToTwip(2),
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                columnSpan: 5,
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      doc.addSection({
        children: [
          new docx.Paragraph(image5),
          new docx.Paragraph({
            text: "DEFICIENCY REPORT RANSOMWARE READINESS ASSESSMENT (RRA)",
            style: "mainHeading",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: moment().format("DD MMMM YYYY"),
            heading: docx.HeadingLevel.HEADING_1,
            style: "date",
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),

          bannertable,
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: msgConstants.DEFICIENCY_REPORT,
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: "This report was prepared as an account of work sponsored by an agency of the U.S. Government. Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or the results of such use, or any information, apparatus, product, or process disclosed in this publication, or represents that its use by such third party would not infringe privately owned rights. ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            style: "normalPara",
            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: "This deficiency report lists the statements that are not currently compliant or 'No' answers This is often referred to as the GAP Report. This report intends to list the gaps, assist users of the report in identifying gaps, prioritizing work, and beginning to make a plan to address the gaps by implementing the controls necessary to come into compliance with the associated statement.The percentage gap in each domain is also listed and will help to determine the priority. CMMC is a cumulative maturity model meaning lower levels should be completed before moving to higher levels.Ideally baseline should be completed before focusing efforts on controls in evolving and so forth up the line of maturity levels.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph(image1),
          new docx.Paragraph({
            text: "Deficiencies",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          new docx.Paragraph({
            text: "Basic",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
              before: 300,
            },
          }),
          BasicDeficiencyTable,
          new docx.Paragraph({
            text: "Intermediate",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
              before: 300,
            },
          }),
          IntermediateDeficiencyTable,
          new docx.Paragraph({
            text: "Advanced",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
              before: 300,
            },
          }),
          AdvancedDeficiencyTable,
        ],
      });

      docx.Packer.toBlob(doc)
        .then(async (blob: any) => {
          if (from && from === "result") {
            await Promise.resolve()
              .then(() => {
                setLoading(false);
                data = blob;
                data["name"] = "RRA_Deficiency_Report.docx";
                handleChange(data);
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
                Sentry.captureException(err)
              });
          } else {
            await Promise.resolve(saveAs(blob, "RRA_Deficiency_Report.docx"))
              .then(() => {
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
                Sentry.captureException(err)
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          Sentry.captureException(err)
        });
  };

  const startLoader = async () => {
    setLoading(true);
    setTimeout(function () {
      generatePdf();
    }, 1000);
  };

  const generatePdf = async () => {
    try {
      setLoading(true);
      const pdf: any = new jsPDF();
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(12);
      const imageBase64Data1 = chartRef1?.current.chartInstance.toBase64Image();
      //cover page
      pdf.addImage(bannerImage, "JPEG", 10, 10, 190, 125);
      pdf.setDrawColor(0);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(40);
      pdf.setFont("helvetica", "bold");
      pdf.text("DEFICIENCY REPORT", 20, 150);
      pdf.text("RANSOMWARE", 20, 165);
      pdf.text("READINESS ", 20, 180);
      pdf.text("ASSESSMENT (RRA)", 20, 195);
      pdf.setFontSize(20);
      const date = moment().toDate();
      const formatedDate = moment(date).format("DD MMMM YYYY");
      pdf.text(formatedDate, 20, 205);
      pdf.setFontSize(10);
      pdf.text("Prepared For", 20, 220);
      pdf.text("Prepared By", 110, 220);
      pdf.setDrawColor(75, 0, 126);
      pdf.setLineWidth(0.75);
      pdf.line(100, 220, 100, 260);
      if (cpaImage !== undefined) {
        pdf.addImage(
          cpaImage,
          "JPEG",
          20,
          225,
          fitImg(18, cpaImageDimensions),
          18
        );
      }
      if (partnerImage !== undefined) {
        pdf.addImage(
          partnerImage,
          "JPEG",
          107,
          230,
          fitImg(18, partnerImageDimensions),
          18
        );
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.text(param.clientInfo.name, 20, 255);
      pdf.text(partnerData.contact_id.name, 110, 255);
      pdf.text("", 110, 260);

      // first page
      pdf.addPage("a4", "p");
      //header
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.setFontSize(18);
      pdf.text(msgConstants.DEFICIENCY_REPORT, 10, 20);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      const description = pdf.splitTextToSize(
        "This report was prepared as an account of work sponsored by an agency of the U.S. Government. Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or the results of such use, or any information, apparatus, product, or process disclosed in this publication, or represents that its use by such third party would not infringe privately owned rights. ",
        190
      );
      pdf.text(description, 10, 30);
      const defiDescription = pdf.splitTextToSize(
        "This deficiency report lists the statements that are not currently compliant or 'No' answers This is often referred to as the GAP Report. This report intends to list the gaps, assist users of the report in identifying gaps, prioritizing work, and beginning to make a plan to address the gaps by implementing the controls necessary to come into compliance with the associated statement.The percentage gap in each domain is also listed and will help to determine the priority. CMMC is a cumulative maturity model meaning lower levels should be completed before moving to higher levels.Ideally baseline should be completed before focusing efforts on controls in evolving and so forth up the line of maturity levels.",
        190
      );
      pdf.text(defiDescription, 10, 60);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      //footer
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const imgData1 = imageBase64Data1;
      pdf.addImage(imgData1, "JPEG", 15, 100, 190, 0);
      pdf.addPage("a4", "p");
      //header
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.setFontSize(18);
      pdf.text("Basic", 10, 20);
      const basicheaders = [["Practice", "Question", "Response"]];
      const basicTable: any[] = [];
      basicList.forEach((rq: any) => {
        const data = [];
        data.push(rq["question_Title"]);
        data.push(rq["question_Text"]);
        data.push(rq["answer"]);
        basicTable.push(data);
      });
      autoTable(pdf, {
        head: basicheaders,
        body: basicTable,
        margin: { top: 30, left: 10, bottom: 30 },
        theme: "grid",
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          4: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });

      pdf.addPage("a4", "p");
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.setFontSize(18);
      pdf.text("Intermediate", 10, 20);
      const intermediateheaders = [["Practice", "Question", "Response"]];
      const intermediateTable: any[] = [];
      intermediateList.forEach((rq: any) => {
        const data = [];
        data.push(rq["question_Title"]);
        data.push(rq["question_Text"]);
        data.push(rq["answer"]);
        intermediateTable.push(data);
      });
      autoTable(pdf, {
        head: intermediateheaders,
        body: intermediateTable,
        margin: { top: 30, left: 10, bottom: 30 },
        theme: "grid",
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          4: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });

      pdf.addPage("a4", "p");
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.setFontSize(18);
      pdf.text("Advanced", 10, 20);
      const advancedheaders = [["Practice", "Question", "Response"]];
      const AdvancedTable: any[] = [];
      advancedList.forEach((rq: any) => {
        const data = [];
        data.push(rq["question_Title"]);
        data.push(rq["question_Text"]);
        data.push(rq["answer"]);
        AdvancedTable.push(data);
      });
      autoTable(pdf, {
        head: advancedheaders,
        body: AdvancedTable,
        margin: { top: 30, left: 10, bottom: 30 },
        theme: "grid",
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          4: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });

      //header and footer
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 0; i <= pageCount; i++) {
        if (i > 1) {
          pdf.setPage(i);
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0); //paragraph black color
          pdf.text(
            `Page | ${pdf.internal.getCurrentPageInfo().pageNumber - 1}`,
            10,
            290
          );
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          if (partnerImage !== undefined) {
            pdf.addImage(
              partnerImage,
              "JPEG",
              180,
              270,
              width / 10,
              height / 14
            );
          }
        }
      }

      await Promise.resolve(pdf.save("RRA_Deficiency_Report.pdf"))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          Sentry.captureException(error)
        });
    } catch (error){
      setLoading(false);
      console.log("ERR");
      Sentry.captureException(error)
    }
  };

  return (
      <div>
        <CssBaseline />
        <Grid container ref={componentRef}>
          <Grid item md={12}>
            <Typography component="h5" variant="h1">
              {msgConstants.DEFICIENCY_REPORT}
            </Typography>
            <Grid item xs={12} sm={6} className={styles.FooterActions}>
              <div className={styles.prevBtn}>
                <Button
                  id="previous-button1"
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                >
                  {"Previous"}
                </Button>
                <div className={styles.borderButton}>
                  <Button
                    id="doc-generate-button1"
                    variant="contained"
                    color="primary"
                    onClick={() => generate()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; Doc
                  </Button>
                  <Button
                    id="pdf-generate-button1"
                    variant="contained"
                    color="primary"
                    onClick={() => startLoader()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; PDF
                  </Button>
                </div>
              </div>
            </Grid>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formState.errMessage}
              </Alert>
            ) : null}
            <Paper className={styles.paper}>
              {loading ? <SimpleBackdrop /> : null}

              <div>
                <p>
                  This deficiency report lists the statements that are not
                  currently compliant or "No" answers.
                </p>
                <p>
                  This is often referred to as the Gap Report. This report
                  intends to list the gaps so that users of the report can
                  prioritize work and make a plan to implement the controls
                  necessary to satisfy the practice.
                </p>
                <p>
                  The percentage gap in each goal is also listed and will help
                  to prioritize areas to concentrate on.
                </p>
              </div>
              <h3>Suggested Areas for Improvement</h3>
              <p>
                The goals in the assessment are ranked in order of deficiency
                with goals having fewer satisfied practices ranked higher in the
                chart. The bar graph reflects the percentage of practices for
                each goal that are answered "No" or are left unanswered.{" "}
              </p>
              <HorizontalBar
                data={suggestedAreaOfImprovement}
                ref={chartRef1}
                options={options}
              />
              <h3>Deficiencies</h3>
              <div className={styles.ScrollTable}>
                {"Basic"}
                {basicList.length > 0 ? (
                  <MaterialTable
                    columns={column}
                    data={basicList}
                    pageSize={25}
                  />
                ) : null}
              </div>
              <div className={styles.ScrollTable}>
                {"Intermediate"}
                {intermediateList.length !== 0 ? (
                  <MaterialTable
                    columns={column}
                    data={intermediateList}
                    pageSize={25}
                  />
                ) : null}
              </div>
              <div className={styles.ScrollTable}>
                {"Advanced"}
                {advancedList.length !== 0 ? (
                  <MaterialTable
                    columns={column}
                    data={advancedList}
                    pageSize={25}
                  />
                ) : null}
              </div>
            </Paper>
            <Grid item xs={12} sm={6} className={styles.FooterActions}>
              <div className={styles.prevBtn}>
                <Button
                  id="previous-button2"
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                >
                  {"Previous"}
                </Button>
                <div className={styles.borderButton}>
                  <Button
                    id="doc-generate-button2"
                    variant="contained"
                    color="primary"
                    onClick={() => generate()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; Doc
                  </Button>
                  <Button
                    id="pdf-generate-button2"
                    variant="contained"
                    color="primary"
                    onClick={() => startLoader()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; PDF
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
  );
};

export default RRADeficiencyReport;
