import React, { useEffect, useState } from "react";
import styles from "./CommentAndReview.module.css";
import logout from "../../../../Auth/Logout/Logout";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import GetAppIcon from "@mui/icons-material/GetApp";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import { Button } from "../../../../../components/UI/Form/Button/Button";
import * as msgConstants from "../../../../../common/MessageConstants";
import * as routeConstant from "../../../../../common/RouteConstants";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import autoTable from "jspdf-autotable";
import { CSET_URL } from "../../../../../config/index";
import DOMPurify from "dompurify";
import * as docx from "docx";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import moment from "moment";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface CommentAndReviewProps {
  from?: string;
  props?: any;
  reportBlobData?: any;
  handleChange?: any;
  location?: any;
  partnerLogo?: any;
  clientLogo?: any;
  partnerImageDimension?: any;
  clientImageDimension?: any;
}

export const CommentAndReview: React.FC<CommentAndReviewProps> = ({
  from,
  reportBlobData,
  props,
  handleChange,
  partnerLogo,
  clientLogo,
  clientImageDimension,
  partnerImageDimension,
}: CommentAndReviewProps) => {
  const history = useNavigate();
  const location: any = useLocation();
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [loading, setLoading] = useState<any>(false);
  const [commentData, setCommentData] = useState<any>();
  const [markedForReviewData, setMarkedForReviewData] = useState<any>();
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");
  const [partnerImage, setPartnerImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const preparedForBy = "preparedFor/By";
  const envVariables = process.env;
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const Commentcolumn = [
    { title: "Practice", field: "question_title" },
    { title: "Question", field: "question_text" },
    { title: "Comment", field: "comment" },
  ];

  const Reviewcolumn = [
    { title: "Practice", field: "question_title" },
    { title: "Question", field: "question_text" },
  ];

  useEffect(() => {
    const controller = new AbortController();
    if (props) {
      setParam(props);
    } else {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    }
    getCommentsReview();
    return () => controller.abort();
  }, []);

  const handleCancel = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  useEffect(() => {
    if (param.clientInfo) {
      GetOrganizationDataOfPartner({
        variables: {
          where: {
            contact_id: param?.clientInfo.partnerId,
          },
        },
      });
      GetOrganizationDataOfClient({
        variables: {
          where: {
            id: param?.clientInfo.clientOrgId,
            subtype: "Client",
          },
        },
      });
    }
  }, [param, props]);

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param?.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err:any) => {
      handleCancel(err);
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err:any) => {
      handleCancel(err);
    },
  });

  const generateTableHeaderCell = (text: string) => {
    return new docx.TableCell({
      children: [new docx.Paragraph({ text, style: "TableHeading" })],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "#d9d2e9",
        color: "auto",
      },
      verticalAlign: docx.VerticalAlign.CENTER,
    });
  };

  const generateTableIndividualCell = (text: string) => {
    let t = text;
    if (typeof text === "number") {
      t = String(text);
    }
    if (t === "N") {
      t = "No";
    }
    if (t === "U") {
      t = "Unanswered";
    }
    let tParas: any = [];
    if (Array.isArray(t)) {
      tParas = t.map((it: any) => {
        return new docx.Paragraph({
          text: it,
          heading: docx.HeadingLevel.HEADING_3,
        });
      });
    }
    return new docx.TableCell(
      Array.isArray(t)
        ? {
            children: [...tParas],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
        : {
            children: [
              new docx.Paragraph({
                text: t,
                heading: docx.HeadingLevel.HEADING_3,
              }),
            ],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
    );
  };

  const generateTableCells = (data: any, columns: any[]) => {
    let tableRows: any[] = [];
    tableRows = columns.map((c) => {
      return generateTableIndividualCell(data[c.field]);
    });
    return new docx.TableRow({
      children: [...tableRows],
    });
  };

  const generateTableHeaderRow = (columns: any[]) => {
    let tableColumns: any[] = [];
    tableColumns = columns.map((c) => {
      return generateTableHeaderCell(c.title);
    });
    return new docx.TableRow({
      children: [...tableColumns],
    });
  };

  const generateFinalTable = (data: any[], columns: any[]) => {
    const rows: any[] = data.map((d) => {
      return generateTableCells(d, columns);
    });
    return new docx.Table({
      rows: [generateTableHeaderRow(columns), ...rows],
    });
  };

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };

  const getCommentsReview = async () => {
    setLoading(true);
    await fetch(`${CSET_URL}api/getCommentsMarked`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.json())
      .then((data) => {
        const commentArr: any = [];
        const markedforreviewArr: any = [];
        data?.comments.forEach((element: any) => {
          const obj: any = {};
          obj["question_title"] = element.mat.question_Title;
          obj["question_text"] = element.mat.question_Text;
          obj["comment"] = element.answer.comment;
          commentArr.push(obj);
        });
        data.markedForReviewList.forEach((element: any, i: any) => {
          const obj: any = {};
          obj["question_title"] = element.mat.question_Title;
          obj["question_text"] = element.mat.question_Text;
          markedforreviewArr.push(obj);
        });
        setLoading(false);
        setCommentData(commentArr);
        setMarkedForReviewData(markedforreviewArr);
      }).catch((err:any)=>{
        sentry_error_catch(err,setLoading,setFormState)
      })
    setLoading(false);
  };
  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  const getBannerImages = async () => {
    await fetch(`${process.env.PUBLIC_URL}/images/ExecutiveSummary.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response: any) => response.blob())
      .then((data: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error: any) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    await fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData?.logo !== undefined && clientData?.logo[0]) {
        const clientLogoUrl = clientData?.logo[0].url.slice(1);
        await fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response: any) => response.blob())
          .then((data: any) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setCPAImage(base64data);
            };
          })
          .catch((error: any) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
      if (partnerData?.logo && partnerData?.logo[0]) {
        const partnerLogoUrl = partnerData?.logo[0].url.slice(1);
        await fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response: any) => response.blob())
          .then((data: any) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error: any) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
    }
  };

  useEffect(() => {
    if (from && from === "result" && cpaImage && partnerImage) {
      setTimeout(() => {
        generate();
      }, 800);
    }
  }, [
    bannerImage && partnerData.contact_id && markedForReviewData && commentData && cpaImage && partnerImage
  ]);

  useEffect(() => {
    getBannerImages();
  }, [partnerData, param.client]);

  const generate = async () => {
    if (
      bannerImage &&
      partnerData.contact_id !== undefined &&
      commentData &&
      markedForReviewData
    ) {
      const docComment = [...commentData];
      const deepDocCommentCopyArray = docComment.map((item: any) =>
        Array.isArray(item) ? [item] : { ...item }
      );
      deepDocCommentCopyArray.forEach((rq: any) => {
        const sanitizedHTML = createDangerousHTML(rq["question_text"]);
        const plainText = sanitizedHTML.__html
          .replace(/&nbsp;/g, "\n")
          .replace(/<[^>]+>/g, "");
        rq["question_text"] = plainText.split("\n");
      });
      const CommentTable1 = generateFinalTable(
        deepDocCommentCopyArray,
        Commentcolumn
      );
      const docMarkedForReviewData = [...markedForReviewData];
      const deepDocMarkedForReviewCopyArray = docMarkedForReviewData.map(
        (item: any) => (Array.isArray(item) ? [item] : { ...item })
      );
      deepDocMarkedForReviewCopyArray.forEach((rq: any) => {
        const sanitizedHTML = createDangerousHTML(rq["question_text"]);
        const plainText = sanitizedHTML.__html
          .replace(/&nbsp;/g, "\n")
          .replace(/<[^>]+>/g, "");
        rq["question_text"] = plainText.split("\n");
      });
      const ReviewTable2 = generateFinalTable(
        deepDocMarkedForReviewCopyArray,
        Reviewcolumn
      );
      setLoading(true);
      const doc = new docx.Document({
        numbering: {
          config: [
            {
              reference: "top_categories",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
            {
              reference: "questions",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
          ],
        },
        styles: {
          default: {
            heading1: {
              run: {
                font: "Helvetica",
                size: 36,
                bold: true,
                color: "#4b0079",
              },
              paragraph: {
                alignment: docx.AlignmentType.LEFT,
                spacing: { line: 340 },
              },
            },
            heading2: {
              run: {
                font: "Helvetica",
                size: 30,
                bold: true,
                color: "#0b4785",
              },
              paragraph: {
                spacing: { line: 340 },
              },
            },
            heading3: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: { line: 276 },
              },
            },
            heading4: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                alignment: docx.AlignmentType.JUSTIFIED,
              },
            },
          },
          paragraphStyles: [
            {
              id: "normalPara",
              name: "Normal Para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: {
                  line: 276,
                  before: 20 * 72 * 0.1,
                  after: 20 * 72 * 0.05,
                },
                rightTabStop: docx.TabStopPosition.MAX,
                leftTabStop: 453.543307087,
              },
            },
            {
              id: "mainHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 80,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "date",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 40,
                bold: true,
              },
            },
            {
              id: "bulletText",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "customFontCng",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "miniHeader",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 24,
                bold: true,
              },
            },
            {
              id: "TableHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "BoldPara",
              name: "Normal Para3",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                bold: true,
              },
            },
            {
              id: preparedForBy,
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 20,
                bold: true,
              },
            },
            {
              id: "clientPartner",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
          ],
        },
      });

      const exeSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
      const defaultcpaLogo = defaultCPAImage
        ? defaultCPAImage.split(",")[1]
        : "";
      const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
      const msplogo = partnerImage
        ? partnerImage.split(",")[1]
        : defaultcpaLogo;

      const image5 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(exeSummaryLogo), (c) => c.charCodeAt(0)),
        600,
        350
      );

      const image6 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
        fitImg(50, cpaImageDimensions),
        50
      );

      const image7 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50
      );
      const footerLogo = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50,
        {
          floating: {
            horizontalPosition: {
              align: docx.HorizontalPositionAlign.RIGHT,
            },
            verticalPosition: {
              align: docx.VerticalPositionAlign.BOTTOM,
            },
          },
        }
      );

      const bannertable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared for",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),

                  new docx.Paragraph(image6),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: param?.clientInfo.name,
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.2),
                  right: docx.convertInchesToTwip(1.8),
                },
                columnSpan: 5,
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },

                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared By",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph(image7),
                  new docx.Paragraph({
                    text: "",
                  }),
                  new docx.Paragraph({
                    text: partnerData.contact_id?.name,
                    style: "clientPartner",
                  }),
                  new docx.Paragraph({
                    text: "",
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.5),
                  right: docx.convertInchesToTwip(2),
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                columnSpan: 5,
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      doc.addSection({
        children: [
          new docx.Paragraph(image5),
          new docx.Paragraph({
            text: "Comment and Review Report",
            style: "mainHeading",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: moment().format("DD MMMM YYYY"),
            heading: docx.HeadingLevel.HEADING_1,
            style: "date",
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),

          bannertable,
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "Comments",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          commentData.length > 0
            ? CommentTable1
            : new docx.Paragraph({
                text: "No Comments to be Displayed",
                heading: docx.HeadingLevel.HEADING_3,
                alignment: docx.AlignmentType.LEFT,
                spacing: {
                  after: 300,
                },
              }),
          new docx.Paragraph({
            text: "Questions Marked for Review",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          markedForReviewData.length > 0
            ? ReviewTable2
            : new docx.Paragraph({
                text: "No Questions Marked for Review",
                heading: docx.HeadingLevel.HEADING_3,
                alignment: docx.AlignmentType.LEFT,
                spacing: {
                  after: 300,
                },
              }),
        ],
      });

      docx.Packer.toBlob(doc)
        .then(async (blob: any) => {
          if (from && from === "result") {
            await Promise.resolve()
              .then(() => {
                setLoading(false);
                reportBlobData = blob;
                reportBlobData["name"] = "Comment_And_Review_Report.docx";
                handleChange(reportBlobData);
              })
              .catch((err) => {
                console.log(err);
                Sentry.captureException(err)
              });
          } else {
            await Promise.resolve(
              saveAs(blob, "Comment_And_Review_Report.docx")
            )
              .then(() => {
                setLoading(false);
              })
              .catch((err) => {
                console.log(err);
                Sentry.captureException(err)
              });
          }
        })
        .catch((err: any) => {
          console.log("ERRor", err);
          Sentry.captureException(err)
        });
    }
  };

  const startLoader = async () => {
    try {
      setLoading(true);
      const pdf: any = new jsPDF();
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(12);
      //cover page
      pdf.addImage(bannerImage, "JPEG", 10, 10, 190, 125);
      pdf.setDrawColor(0);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(40);
      pdf.setFont("helvetica", "bold");
      pdf.text("Comment and Review", 20, 150);
      pdf.text("Report", 20, 165);
      pdf.setFontSize(20);
      const date = moment().toDate();
      const formatedDate = moment(date).format("DD MMMM YYYY");
      pdf.text(formatedDate, 20, 180);
      pdf.setFontSize(10);
      pdf.text("Prepared For", 20, 200);
      pdf.text("Prepared By", 110, 200);
      pdf.setDrawColor(75, 0, 121);
      pdf.setLineWidth(0.75);
      pdf.line(100, 200, 100, 240);
      if (cpaImage !== undefined) {
        pdf.addImage(
          cpaImage,
          "JPEG",
          20,
          205,
          fitImg(18, cpaImageDimensions),
          18
        );
      }
      if (partnerImage !== undefined) {
        pdf.addImage(
          partnerImage,
          "JPEG",
          107,
          205,
          fitImg(18, partnerImageDimensions),
          18
        );
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.text(param.clientInfo.name, 20, 235);
      pdf.text(partnerData.contact_id.name, 110, 235);
      pdf.text("", 110, 240);

      //footer
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);

      const Commentheaders = [["Practice", "Question", "Comment"]]; //"Response", "Comment"]];
      const Reviewheaders = [["Practice", "Question"]]; // "Response"]];
      const commentTable: any[] = [];
      const reviewTable: any[] = [];
      const pdfComment = [...commentData];
      pdfComment.forEach((rq: any) => {
        const data = [];
        data.push(rq["question_title"]);
        const sanitizedHTML = createDangerousHTML(rq["question_text"]);
        const plainText = sanitizedHTML.__html
          .replace(/&nbsp;/g, "\n")
          .replace(/<[^>]+>/g, "");
        data.push(plainText);
        data.push(rq["comment"]);
        commentTable.push(data);
      });
      const pdfMarkedForReviewData = [...markedForReviewData];
      pdfMarkedForReviewData.forEach((rq: any) => {
        const data = [];
        data.push(rq["question_title"]);
        const sanitizedHTML = createDangerousHTML(rq["question_text"]);
        const plainText = sanitizedHTML.__html
          .replace(/&nbsp;/g, "\n")
          .replace(/<[^>]+>/g, "");
        data.push(plainText);
        reviewTable.push(data);
      });

      if (commentTable.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text("", 10, 10);
        pdf.setFont("helvetica", "bold");
        pdf.setTextColor(75, 0, 121); //heading blue color
        pdf.setFontSize(18);
        pdf.text("Comments", 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: Commentheaders,
          body: commentTable,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      } else {
        pdf.addPage("a4", "p");
        pdf.text("", 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setTextColor(75, 0, 121); //heading blue color
        pdf.setFontSize(18);
        pdf.text("No Comments to be Displayed.", 10, 30);
      }
      if (reviewTable.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text("", 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setTextColor(75, 0, 121); //heading blue color
        pdf.setFontSize(18);
        pdf.text("Questions Marked for Review", 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: Reviewheaders,
          body: reviewTable,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      } else {
        pdf.addPage("a4", "p");
        pdf.text("", 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setTextColor(75, 0, 121); //heading blue color
        pdf.setFontSize(18);
        pdf.text("No Questions Marked for Review.", 10, 30);
      }

      //header and footer
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 0; i <= pageCount; i++) {
        if (i > 1) {
          pdf.setPage(i);
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0); //paragraph black color
          pdf.text(
            `Page |  ${pdf.internal.getCurrentPageInfo().pageNumber - 1}`,
            10,
            290
          );
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          if (partnerImage !== undefined) {
            pdf.addImage(
              partnerImage,
              "JPEG",
              180,
              270,
              width / 10,
              height / 14
            );
          }
        }
      }

      await Promise.resolve(pdf.save("Comment_And_Review_Report.pdf"))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          Sentry.captureException(error)
        });
    } catch (err) {
      setLoading(false);
      Sentry.captureException(err)
      console.log("ERR", err);
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleBack = () => {
    history(routeConstant.CMMC2_REPORTS, { state: { param } });
  };
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={styles.FooterActions}>
          <div className={styles.prevBtn}>
            <Button
              id="previous-button"
              variant="contained"
              color="primary"
              onClick={handleBack}>
              {"Previous"}
            </Button>
            <div className={styles.borderButton}>
              <Button
                id="doc-generate-button-1"
                variant="contained"
                color="primary"
                onClick={() => generate()}>
                <GetAppIcon className={styles.EditIcon} />
                &nbsp; Doc
              </Button>
              <Button
                id="pdf-generate-button-1"
                variant="contained"
                color="primary"
                onClick={() => startLoader()}>
                <GetAppIcon className={styles.EditIcon} />
                &nbsp; PDF
              </Button>
            </div>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formState.errMessage}
              </Alert>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <div>
        <Paper className={styles.paper}>
          {loading ? <SimpleBackdrop /> : null}
          <Typography component="h3" variant="h3">
            Comments
          </Typography>
          <Grid item xs={8} md={12} sm={8} className={styles.FooterActions}>
            <div className={styles.securityPlanTable}>
              <table id="secondTable" className={styles.tableStyle}>
                <tr>
                  <td colSpan={1}>
                    {" "}
                    <Typography component="h3" variant="h3">
                      Practice
                    </Typography>
                  </td>
                  <td colSpan={1}>
                    {" "}
                    <Typography component="h3" variant="h3">
                      Question
                    </Typography>
                  </td>

                  <td colSpan={1}>
                    {" "}
                    <Typography component="h3" variant="h3">
                      Comment
                    </Typography>
                  </td>
                </tr>
                {commentData?.length > 0 ? (
                  commentData.map((questionData: any, index: number) => {
                    return (
                        <tr key={index}>
                          <td colSpan={1}>
                            Practice {questionData.question_title}
                          </td>
                          <td colSpan={1}>
                            {
                              <div
                                className={styles.qtext}
                                dangerouslySetInnerHTML={createDangerousHTML(
                                  questionData.question_text
                                )}></div>
                            }
                          </td>
                          <td colSpan={1}>{questionData.comment}</td>
                        </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      "No Comments to be Shown."
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </Grid>
          <Typography component="h3" variant="h3">
            Question Marked for Review
          </Typography>
          <Grid item xs={8} md={12} sm={8} className={styles.FooterActions}>
            <div className={styles.securityPlanTable}>
              <table id="secondTable" className={styles.tableStyle}>
                <tr>
                  <td colSpan={1}>
                    <Typography component="h3" variant="h3">
                      Practice
                    </Typography>
                  </td>
                  <td colSpan={1}>
                    {" "}
                    <Typography component="h3" variant="h3">
                      Question
                    </Typography>
                  </td>
                </tr>
                {markedForReviewData?.length > 0 ? (
                  markedForReviewData.map(
                    (questionData: any, index: number) => {
                      return (
                          <tr key={index}>
                            <td colSpan={1}>
                              Practice {questionData.question_title}{" "}
                            </td>
                            <td colSpan={1}>
                              {
                                <div
                                  className={styles.qtext}
                                  dangerouslySetInnerHTML={createDangerousHTML(
                                    questionData.question_text
                                  )}></div>
                              }
                            </td>
                          </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      "No Comments to be Shown."
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </Grid>
        </Paper>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={styles.FooterActions}>
          <div className={styles.prevBtn}>
            <Button
              id="previous-button"
              variant="contained"
              color="primary"
              onClick={handleBack}>
              {"Previous"}
            </Button>
            <div className={styles.borderButton}>
              <Button
                id="doc-generate-button-2"
                variant="contained"
                color="primary"
                onClick={() => generate()}>
                <GetAppIcon className={styles.EditIcon} />
                &nbsp; Doc
              </Button>
              <Button
                id="pdf-generate-button-2"
                variant="contained"
                color="primary"
                onClick={() => startLoader()}>
                <GetAppIcon className={styles.EditIcon} />
                &nbsp; PDF
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CommentAndReview;
